import * as CANNON from 'cannon-es'

export class PhysicsObject {
    body : CANNON.Body;

    constructor(body: CANNON.Body) {
        this.body = body;
    }

    setRotation(x: number, y: number, z: number) {
        this.body.quaternion.setFromEuler(x,y,z)
        return this;
    }

}