import {Camera, Euler, Matrix3, Matrix4, PerspectiveCamera, Vector3} from "three";
import * as THREE from "three";
import {DebugLogger} from "./DebugLogger";
import {DebugSettings} from "./DebugSettings";

export class DebugCamera {
    private debugLogger: DebugLogger;
    get aspect(): number {
        return this._aspect;
    }

    set aspect(value: number) {
        this._aspect = value;
        this.camera.aspect = value;
        this.camera.updateProjectionMatrix();
    }
    private _aspect: number = 640/480;
    get pitch(): number {
        return this.debugSettings.cameraPitch.value;
    }

    set pitch(value: number) {
        if (value>=-1.5 && value<1.5) {
            this.debugSettings.cameraPitch.value = value;
        }
        this.update();
    }
    get yaw(): number {
        return this.debugSettings.cameraYaw.value;
    }

    set yaw(value: number) {
        this.debugSettings.cameraYaw.value = value;
        this.update();
    }
    get position(): Vector3 {
        return this.debugSettings.cameraPosition.value;
    }

    set position(value: Vector3) {
        this.debugSettings.cameraPosition.value = value;
        this.update();
    }
    //private _position = new Vector3(100,0,30);
   // private _yaw = 1.5;
   // private _pitch = -0.2;
    reusableVector = new Vector3(0,1,0);
    private reusableEuler = new Euler(0,0,0);
    public camera: PerspectiveCamera;
    private reusableMatrix1 = new Matrix4().identity();
    private reusableMatrix2 = new Matrix4().identity();
    private debugSettings: DebugSettings;


    constructor(debugLogger: DebugLogger, debugSettings: DebugSettings) {
        this.debugLogger = debugLogger;
        this.debugSettings = debugSettings;
        this.camera = new THREE.PerspectiveCamera(30, window.innerWidth / window.innerHeight, 0.1, 5000000);
        this.camera.up.set(0, 0, 1);
        this.update();
        this.camera.setFocalLength(35);
    }


    update () {
        this.camera.position.copy(this.position);
        let matrix1 = this.reusableMatrix1.identity();
        let matrix2 = this.reusableMatrix2.identity();
        let lookAt = this.reusableVector.set(0,1,0);
        matrix1.makeRotationFromEuler(this.reusableEuler.set(0,0, this.yaw));
        matrix2.makeRotationFromEuler(this.reusableEuler.set(this.pitch,0, 0));
        matrix1.multiply(matrix2);
        lookAt.applyMatrix4(matrix1);
//        lookAt.setFromEuler(euler);
        lookAt.add(this.position);
        this.camera.lookAt(lookAt);
        this.camera.updateProjectionMatrix();
      //  this.debugLogger.print(`Pitch: ${this._pitch} Yaw: ${this._yaw}`);
      //      this.debugLogger.print("Pos: ",this._position, "Lookat: ", lookAt);

    }

    moveForward(distance: number) {
        let matrix1 = this.reusableMatrix1.identity();
        let matrix2 = this.reusableMatrix2.identity();
        let lookAt = this.reusableVector.set(0,2,0);
        matrix1.makeRotationFromEuler(this.reusableEuler.set(0,0, this.yaw));
        matrix2.makeRotationFromEuler(this.reusableEuler.set(this.pitch,0, 0));
        matrix1.multiply(matrix2);

        lookAt.applyMatrix4(matrix1);
        lookAt.multiplyScalar(distance);
        this.position = this.position.add(lookAt);
        this.update();
    }
}