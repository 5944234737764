import {Vector3} from "three";

export class VectorSetting {
    private listeners: ((value: Vector3) => void)[] = [];

    constructor(x: number, y: number, z: number) {
        this._value.set(x,y,z);
    }

    listen(listener: (value: Vector3) => void, callImmediately : boolean = false) {
        this.listeners.push(listener);
        if (callImmediately) {
            listener(this.value);
        }
        return this;
    }

    set value(value: Vector3) {
        this._value.set(value.x, value.y, value.z);
        this.listeners.forEach(listener => {
            listener(value);
        });
    }

    get value(): Vector3 {
        return this._value;
    }

    putInto(outValue : Vector3) {
        outValue.set(this._value.x, this._value.y, this._value.z);
    }

    private _value: Vector3 = new Vector3(0, 0, 0);
}
