export class BooleanSetting {
    private listeners: ((value: boolean) => void)[] = [];

    constructor(value: boolean = false) {
        this._value = value;
    }

    listen(listener: (value: boolean) => void, callImmediately : boolean = false) {
        this.listeners.push(listener);
        if (callImmediately) {
            listener(this.value);
        }
        return this;
    }

    set value(value: boolean) {
        this._value = value;
        this.listeners.forEach(listener => {
           listener(value);
        });
    }

    get value(): boolean {
        return this._value;
    }

    private _value: boolean = false;
}
