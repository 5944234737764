import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {Model} from "./Model";

import {
    BackSide,
    DoubleSide,
    FrontSide, LinearFilter,
    Material,
    Mesh,
    MeshPhongMaterial,
    RepeatWrapping,
    TextureLoader,
    Vector3
} from "three";
import {MeshPhongMaterialParameters} from "three/src/materials/MeshPhongMaterial";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DebugLogger} from "./editor/DebugLogger";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";

export class ModelLoader {
    private fbxLoader = new FBXLoader();
    private gltfLoader = new GLTFLoader();
    private objLoader = new OBJLoader();
    private materials = new Map<string, Material>();
    private debugLogger: DebugLogger;

    constructor(debugLogger: DebugLogger) {
        this.debugLogger = debugLogger;
        //   MeshoptDecoder.setWasmPath('/decoder_base.wasm');
      //  this.gltfLoader.setMeshoptDecoder(MeshoptDecoder);
        let defaultMaterial = new  MeshPhongMaterial ({color: 0xff00ff, side: FrontSide});
        this.materials.set("DEFAULT", defaultMaterial);
    }


    defineTextureMaterial(name : string, textureFileName : string, repeatCount : number) {
        const loader = new TextureLoader();
        const texture = loader.load(textureFileName);
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.repeat.set( repeatCount, repeatCount );
        texture.generateMipmaps = false;
        texture.magFilter = LinearFilter;
        texture.minFilter = LinearFilter;

        let parameters : MeshPhongMaterialParameters = {color: 0xffffff, map: texture,transparent: true, premultipliedAlpha:false, side: DoubleSide};
        let material = new  MeshPhongMaterial(parameters )
        this.materials.set(name, material);
    }
    defineMaterial(name : string, material: Material) {
        this.materials.set(name, material);
    }

    async loadFBX(pathName : string) {
        const loadedObject = await this.fbxLoader.loadAsync(pathName);
        loadedObject.scale.set(0.01,0.01, 0.01);
        loadedObject.updateMatrix();
        loadedObject.traverse(object => {
            if (object instanceof Mesh) {
                if (object.material instanceof Material) {
                    if (this.materials.has(object.material.name)) {
                        object.material = this.materials.get(object.material.name);
                    } else {
                        this.debugLogger.print("Unknown material "+object.material.name+" in model "+pathName);
                        object.material = this.materials.get("DEFAULT");
                    }
                }
            }
        })

        return new Model(loadedObject);
    }

    async loadGLTF(pathName : string) {
        const loadedObject = await this.gltfLoader.loadAsync(pathName);
        loadedObject.scene.traverse(object => {
            if (object instanceof Mesh) {
                if (object.material instanceof Material) {
                    if (this.materials.has(object.material.name)) {
                        object.material = this.materials.get(object.material.name);
                    } else {
                        this.debugLogger.print("Unknown material "+object.material.name+" in model "+pathName);
                        if (!object.material)
                        {
                            object.material = this.materials.get("DEFAULT");
                        }
                    }
                }
            }
        })
        let obj = loadedObject.scene;
        return new Model(obj);
    }


    async loadObj(pathName : string) {
        const loadedObject = await this.objLoader.loadAsync(pathName);
        loadedObject.traverse(object => {
            if (object instanceof Mesh) {
                if (object.material instanceof MeshPhongMaterial) {
                    object.material.wireframe = false;
                }
                if (object.material instanceof Material) {
                    if (this.materials.has(object.material.name)) {
                        object.material = this.materials.get(object.material.name);
                    } else {
                        this.debugLogger.print("Unknown material "+object.material.name+" in model "+pathName);
                        if (!object.material)
                        {
                            object.material = this.materials.get("DEFAULT");
                        }
                    }
                }
            }
        })
        loadedObject.scale.set(0.005,0.005, 0.005);
        loadedObject.updateMatrix();
        //   loadedObject.scale.set(0.01,0.01, 0.01);
        //    loadedObject.updateMatrix();
        /*loadedObject.traverse(object => {
            if (object instanceof Mesh) {
                if (object.material instanceof Material) {
                    if (this.materials.has(object.material.name)) {
                        object.material = this.materials.get(object.material.name);
                    } else {
                        console.error("Unknown material "+object.material.name+" in model "+pathName);
                        object.material = this.materials.get("DEFAULT");
                    }
                }
            }
        })
*/
//       let obj = loadedObject.scene.children[0];

        let obj = loadedObject;

        //obj.rotateX(3.16/2)
        obj.children.forEach(subObject => {
            subObject.rotation.x += 3.16/2;
            // subObject.rotateOnWorldAxis(new Vector3(1,0,0),3.16/2);
        })

        obj.up.set(0,0,1);

        //obj.scale.set(loadedObject.scene.scale.x,loadedObject.scene.scale.y,loadedObject.scene.scale.z);
        obj.updateMatrix();
        return new Model(obj);
    }
}