import * as THREE from "three";
import {RenderObject} from "./RenderObject";
import {Material, Object3D, Raycaster} from "three";
import {DebugCamera} from "./editor/DebugCamera";
import {DebugLogger} from "./editor/DebugLogger";
import {DragListener} from "./editor/DragListener";
import {MyBlur} from "./MyBlur";
import {DebugSettings} from "./editor/DebugSettings";


export class RenderSystem {
    scene = new THREE.Scene();
    fog = new THREE.Fog("#b0b0c0", 50, 200);
    //fog = new THREE.FogExp2("#808080", 0.005);

    renderer = new THREE.WebGLRenderer();
     SHADOW_MAP_WIDTH = 2048;
     SHADOW_MAP_HEIGHT = 2048;
     debugCamera: DebugCamera;

    private object3DtoRenderObject = new Map<string, RenderObject>();
    private pickingRaycaster: Raycaster;
    private debugLogger: DebugLogger;
    private bokehBlur: MyBlur;
    private debugSettings: DebugSettings;
    private postEffects: boolean = true;

    constructor(debugLogger: DebugLogger, debugSettings: DebugSettings) {
        this.scene.fog = this.fog;
        this.debugLogger = debugLogger;
        this.debugCamera = new DebugCamera(debugLogger, debugSettings);
        this.debugSettings = debugSettings;
        let gameScreen: HTMLElement = document.getElementById("game-screen") as HTMLDivElement;
        let gameLayers: HTMLElement = document.getElementById("game-layers") as HTMLDivElement;
        if (!gameScreen) {
            gameScreen = document.body;
        }
        let width = gameScreen.offsetWidth;
        let height = gameScreen.offsetHeight;
        this.bokehBlur = new MyBlur(this.renderer, width, height, this.debugCamera.camera.near, this.debugCamera.camera.far);
        this.pickingRaycaster = new THREE.Raycaster();

//        this.camera.position.set(20, 20, 50);
//        this.camera.up.set(0, 0, 1);
//        this.camera.lookAt(0, 0, 1);
        this.renderer.setSize(width,height);
        //const light = new THREE.DirectionalLight( 0xffffc0, 1 );
      //  const light = new THREE.PointLight( 0xffffc0, 0.7);
        const light = new THREE.PointLight( 0xffffc0, 0.7);

        light.position.set( -100, 100, 200 );
        light.castShadow = true;
        light.shadow.camera.near = 1;
        light.shadow.camera.far = 2500;
        light.shadow.bias = 0.00001;

        light.shadow.mapSize.width = this.SHADOW_MAP_WIDTH;
        light.shadow.mapSize.height = this.SHADOW_MAP_HEIGHT;
        light.shadow.radius = 4;
        light.shadow.blurSamples = 3;
        this.scene.add( light );

        document.body.appendChild(this.renderer.domElement);

        const directionalLight = new THREE.DirectionalLight(0xffffc0,0.7);
        directionalLight.castShadow = false;
        directionalLight.position.set(1,1,1);
        this.scene.add(directionalLight);

        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFShadowMap;

        this.renderer.setSize(width, height);
        this.debugCamera.aspect = width/height;

        gameScreen.appendChild(this.renderer.domElement);

        window.addEventListener( 'resize', (e) => {
            if (gameScreen != null) {
                this.onResize(e, gameScreen);
            }
        }, false );
        gameLayers.addEventListener('click', (e) => {
            this.onClick(e);
        }, {capture:true});
        gameLayers.addEventListener('click', (e) => {
            this.onClick(e);
        }, {capture:true});
        gameLayers.addEventListener('wheel', (e) => {
            return this.onWheel(e);
        }, {capture:true});

        let dragListener = new DragListener(gameLayers);
        dragListener.addDeltaListener((dx, dy) => {
       //     this.debugCamera.yaw+=dx*0.01;
            this.debugCamera.pitch+=dy*0.001;
            this.debugCamera.yaw+=dx*0.001;
        })

        debugSettings.renderMode.listen(value => {
            switch (value) {
                case "Normal":
                    this.postEffects = true;
                    break;
                case "NoPostEffects":
                case "Wireframe":
                case "Nothing":
                    this.postEffects = false;
                    break;
                default:
                    const ensureAllCases : never = value;
            }
        }, true);


    }

    onResize(e: UIEvent, gameDiv: HTMLElement) {
        let width = gameDiv.offsetWidth;
        let height = gameDiv.offsetHeight;
        this.debugCamera.aspect = width/height;
        this.renderer.setSize(width, height);
        this.bokehBlur.setSize(width,height);
    }

    onClick(e: MouseEvent) {
        const rect = this.renderer.domElement.getBoundingClientRect();
        const pos =  {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };
        const pickPosition = {x: 0, y: 0};
        pickPosition.x = (pos.x / this.renderer.domElement.clientWidth ) *  2 - 1;
        pickPosition.y = (pos.y / this.renderer.domElement.clientHeight) * -2 + 1;  // note we flip Y
        this.pick(pickPosition);
    }


    pick(normalizedPosition: { x: number; y: number }) {
        // restore the color if there is a picked object
        //if (this.pickedObject) {
        //    this.pickedObject.material.emissive.setHex(this.pickedObjectSavedColor);
        //    this.pickedObject = undefined;
        //}

        // cast a ray through the frustum
        this.pickingRaycaster.setFromCamera(normalizedPosition, this.debugCamera.camera);
        // get the list of objects the ray intersected
        const intersectedObjects = this.pickingRaycaster.intersectObjects(this.scene.children);
        if (intersectedObjects.length) {
            // pick the first object. It's the closest one
            let pickedObject = intersectedObjects[0].object;
            let pickedRenderObject = this.object3DtoRenderObject.get(pickedObject.uuid);
            if (pickedRenderObject && pickedRenderObject.onClick)
            {
                pickedRenderObject.onClick();
            }


            // save its color
            //   this.pickedObjectSavedColor = this.pickedObject.material.emissive.getHex();
            // set its emissive color to flashing red/yellow
            // this.pickedObject.material.emissive.setHex((time * 8) % 2 > 1 ? 0xFFFF00 : 0xFF0000);
        }
    }
    createCube(x:number,y:number,z:number, width:number, height:number, depth:number, color:number) : RenderObject
    {
        const geometry = new THREE.BoxGeometry(width, height, depth);
//        const material = new THREE.MeshBasicMaterial({color});
        const material = new THREE.MeshPhongMaterial({color});
        const cube = new THREE.Mesh(geometry, material);
        cube.castShadow = true;
        cube.receiveShadow = false;
        cube.position.set(x,y,z);
        this.scene.add(cube);
        let renderObject = new RenderObject(cube);
        this.object3DtoRenderObject.set(cube.uuid,renderObject);
        return renderObject;
    }

    createFromObject3D(object3D : Object3D) : RenderObject {
        this.scene.add(object3D);
        let renderObject = new RenderObject(object3D);
        this.object3DtoRenderObject.set(object3D.uuid,renderObject);
        return renderObject;
    }

    render() {
        let renderer = this.renderer;
        renderer.clear();
        if (this.postEffects) {
            this.bokehBlur.renderInput((overrideMaterial) => {
                this.scene.overrideMaterial = overrideMaterial;
                renderer.clear();
                renderer.render(this.scene, this.debugCamera.camera);
                this.scene.overrideMaterial = null;
            });
            this.renderer.setRenderTarget(null);
            this.bokehBlur.render();
        } else {
            renderer.render(this.scene, this.debugCamera.camera);
        }
    }

    private onWheel(e: WheelEvent) : boolean {
        if (e.deltaY >0 ) {
            this.debugCamera.moveForward(+0.5);
        } else {
            this.debugCamera.moveForward(-0.5);
        }
        return false;
        //this.debugCamera
    }
}