import {Object3D} from "three";

export class RenderObject {
    public mesh : Object3D;
    public onClick = () => {
        let debugConsole = document.getElementById("console");
        if (debugConsole) {
            debugConsole.innerText += `Clicked ID: ${this.mesh.id}\n`;
        }
    };

    constructor(mesh: Object3D) {
        this.mesh = mesh;
    }

}