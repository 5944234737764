import {Object3D} from "three";

export class Model {
    private group: Object3D;

    constructor(loadedObject: Object3D) {
        this.group = loadedObject;
    }

    getRenderObject() : Object3D {
        return this.group;
    }

}