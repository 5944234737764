import {GameObject} from "./GameObject";
import {
    Box3,
    Mesh,
    MeshPhongMaterial,
    MeshPhysicalMaterial,
    MeshStandardMaterial,
    Quaternion,
    ShaderMaterial,
    Vector3
} from "three";
import {PhysicsSystem} from "./PhysicsSystem";
import {RenderSystem} from "./RenderSystem";
import {ModelLoader} from "./ModelLoader";
import {GUI} from "dat.gui";
import CannonDebugger from 'cannon-es-debugger'
import {DebugSettings, RenderMode} from "./editor/DebugSettings";
import {kilograms, meters} from "./types";
import {DebugLogger} from "./editor/DebugLogger";

export class GameSystem {

    gameObjects : GameObject[] = [];
    rotationQuaternion = new Quaternion(0,0,0,0);
    private physicsSystem: PhysicsSystem;
    private renderSystem: RenderSystem;
    private debugLogger: DebugLogger;
    private modelLoader: ModelLoader;
    private _renderMode: RenderMode = "Normal";
    private _showShapes: boolean = false;
    private debugSettings: DebugSettings;

    constructor(physicsSystem: PhysicsSystem, renderSystem: RenderSystem, modelLoader: ModelLoader, debugSettings: DebugSettings, debugLogger: DebugLogger) {
        this.debugLogger = debugLogger;
        this.physicsSystem = physicsSystem;
        this.renderSystem = renderSystem;
        this.debugSettings = debugSettings;
        this.debugSettings.renderMode.listen((value)  => {
               this.renderMode = value;
        },true);
        this.modelLoader = modelLoader;
    }

    addObject(newObject : GameObject) {
        this.gameObjects.push(newObject);
        this.renderMode = this._renderMode;
    }

    async addFBX(fileName: string, x: number,y: number,z: number, mass : number, castShadow : boolean, receiveShadow : boolean) {
        let model = await this.modelLoader.loadFBX(fileName);

        let gameObject = new GameObject();
        gameObject.renderObject = this.renderSystem.createFromObject3D(model.getRenderObject());
        let boundingBox = new Box3().setFromObject(gameObject.renderObject.mesh);
        let size = new Vector3(0,0,0);
        boundingBox.getSize(size);
        gameObject.renderOffset = boundingBox.min.lerp(boundingBox.max, 0.5);

        //  gameObject.physicsObject = this.physicsSystem.createCube(position.x+x, position.y+y, position.z+z, size.x, size.y, size.z, mass);
        gameObject.physicsObject = this.physicsSystem.createCube(x, y, z, size.x, size.y, size.z, mass);
        gameObject.renderObject.mesh.traverse(object => {
            object.castShadow = castShadow;
            object.receiveShadow = receiveShadow;
        })
        this.addObject(gameObject);
        return gameObject;
    }


    async addGLTF(fileName: string, x: meters, y: meters, z: meters, mass : kilograms, castShadow : boolean, receiveShadow : boolean, withPhysics : boolean) {
        let model = await this.modelLoader.loadGLTF(fileName);
        let gameObject = new GameObject();
        gameObject.renderObject = this.renderSystem.createFromObject3D(model.getRenderObject());
            let boundingBox = new Box3().setFromObject(gameObject.renderObject.mesh, true);

            let size = new Vector3(0,0,0);
            boundingBox.getSize(size);
            gameObject.renderOffset = boundingBox.min;

            let middle = boundingBox.min.add(boundingBox.max).multiplyScalar(0.5);
            //  gameObject.physicsObject = this.physicsSystem.createCube(position.x+x, position.y+y, position.z+z, size.x, size.y, size.z, mass);
            gameObject.physicsObject = this.physicsSystem.createCube(middle.x+x, middle.y+y, middle.z+z, size.x, size.y, size.z, mass);
        this.debugLogger.print(new Vector3(middle.x+x, middle.y+y, middle.z+z));
        this.debugLogger.print(size);

        if (!withPhysics) {
            gameObject.physicsObject.body.collisionResponse = false;
        }
        gameObject.renderObject.mesh.traverse(object => {
            object.castShadow = castShadow;
            object.receiveShadow = receiveShadow;
        })
        this.addObject(gameObject);
        return gameObject;
    }


    async addObj(fileName: string, x: number,y: number,z: number, mass : number, castShadow : boolean, receiveShadow : boolean, withPhysics : boolean) {
        let model = await this.modelLoader.loadObj(fileName);
        let gameObject = new GameObject();
        gameObject.renderObject = this.renderSystem.createFromObject3D(model.getRenderObject());
        let boundingBox = new Box3().setFromObject(gameObject.renderObject.mesh);
        let size = new Vector3(0,0,0);
        boundingBox.getSize(size);
        gameObject.renderOffset = boundingBox.min.lerp(boundingBox.max, 0.5);

        //  gameObject.physicsObject = this.physicsSystem.createCube(position.x+x, position.y+y, position.z+z, size.x, size.y, size.z, mass);
        gameObject.physicsObject = this.physicsSystem.createCube(x, y, z, size.x, size.y, size.z, mass);
        if (!withPhysics) {
            gameObject.physicsObject.body.collisionResponse = false;
        }
        gameObject.renderObject.mesh.traverse(object => {
            object.castShadow = castShadow;
            object.receiveShadow = receiveShadow;
        })
        this.addObject(gameObject);
        return gameObject;
    }


    update(dt: number) {
        this.physicsSystem.simulate(dt);
        this.gameObjects.forEach(cube => {
            if (!cube.physicsObject || !cube.renderObject) {
                return;
            }
            let position = cube.physicsObject.body.position;
            cube.renderObject.mesh.position.set(position.x-cube.renderOffset.x, position.y-cube.renderOffset.y, position.z-cube.renderOffset.z);
            this.rotationQuaternion.set(
                cube.physicsObject.body.quaternion.x,
                cube.physicsObject.body.quaternion.y,
                cube.physicsObject.body.quaternion.z,
                cube.physicsObject.body.quaternion.w
            )
            cube.renderObject.mesh.setRotationFromQuaternion(this.rotationQuaternion);

        })
    //    if (this._showShapes) {
    //        this.cannonDebugger.update();
    //    }
        this.renderSystem.render();

    }

    get renderMode() {
        return this._renderMode;
    }

    set renderMode(value) {
        this._renderMode = value;

        this.gameObjects.forEach(cube => {
            if (!cube.renderObject) {
                return;
            }
            cube.renderObject.mesh.traverse(object => {
                if (object instanceof Mesh) {
                    if (object.material instanceof MeshPhongMaterial ||
                        object.material instanceof MeshPhysicalMaterial ||
                        object.material instanceof MeshStandardMaterial ||
                        object.material instanceof ShaderMaterial
                    ) {
                        switch (value) {
                            case "Normal":
                            case "NoPostEffects":
                                object.material.wireframe = false;
                                object.material.visible = true;
                                break;
                            case "Wireframe":
                                object.material.wireframe = true;
                                object.material.visible = true;
                                break;
                            case "Nothing":
                                object.material.wireframe = false;
                                object.material.visible = false;
                                break;
                            default:
                                const exhaustiveCheck: never = value;
                        }
                    } else {
                        this.debugLogger.print("Unknown material: "+ object.material.constructor.name)
                    }
                }
            })
        });
    }

    get showShapes(): boolean {
        return this._showShapes;
    }

    set showShapes(value: boolean) {
        this._showShapes = value;
    }

}