import {RenderSystem} from "./RenderSystem";
import {PhysicsSystem} from "./PhysicsSystem";
import {GameObject} from "./GameObject";
import {GameSystem} from "./GameSystem";
import {ModelLoader} from "./ModelLoader";
import * as THREE from "three";
import * as CANNON from 'cannon-es'
import {FramerateMeter} from "./editor/FramerateMeter";
import {Material, Mesh, ShaderMaterial, Vector3} from "three";
import {DebugLogger} from "./editor/DebugLogger";
import {RenderObject} from "./RenderObject";
import {CannonThreeJSDebugger} from "./editor/CannonThreeJSDebugger";
import {DebugSettings} from "./editor/DebugSettings";
import {SettingsEditor} from "./editor/settingssystem/SettingsEditor";
import {RaycastVehicle} from "objects/RaycastVehicle";
import {Box, Vec3} from "cannon-es";
import {vec3} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

//import CANNON from "cannon-es";
//import {PhysicsDebugRenderer} from "./PhysicsDebugRenderer";
//import {RenderObject} from "./RenderObject";
let thisGame : Game;


class Game {

    private car: GameObject | undefined;
    private forwardForce: number = 0;
    private backwardForce: number = 0;
    private turnRightTorque: number = 0;
    private turnLeftTorque: number = 0;
    private hasOldCamera: boolean = false;
    private oldCameraVector: THREE.Vector3 = new THREE.Vector3();
    private lastTime: number = 0;
    private readonly renderSystem: RenderSystem;
    private gameSystem: GameSystem;
    private readonly physicsSystem: PhysicsSystem;
    private readonly modelLoader: ModelLoader;
    private debugLogger : DebugLogger = new DebugLogger();
    private framerateMeter: FramerateMeter;
    private material2: ShaderMaterial = new THREE.ShaderMaterial();
    private physicsDebugRenderer: CannonThreeJSDebugger;
    private vehicle: { wheels : CANNON.HingeConstraint[], chassis : CANNON.Body | undefined } = {wheels: [], chassis:undefined};

    constructor() {
        let debugSettings = new DebugSettings();
        let globalPropertiesEditor = document.getElementById("global-properties-editor");
        if (globalPropertiesEditor != null) {
            let editorContext = new SettingsEditor(globalPropertiesEditor);
            debugSettings.getProperties(editorContext);
        }
        this.renderSystem = new RenderSystem(this.debugLogger, debugSettings);
        this.physicsSystem = new PhysicsSystem();
   //     this.physicsDebugRenderer = new PhysicsDebugRenderer(this.renderSystem, this.physicsSystem);
        this.physicsDebugRenderer = new CannonThreeJSDebugger(this.debugLogger, this.renderSystem.scene, this.physicsSystem.world, debugSettings);
        this.modelLoader = new ModelLoader(this.debugLogger);
        this.gameSystem = new GameSystem(this.physicsSystem, this.renderSystem, this.modelLoader, debugSettings, this.debugLogger);
        let elementById = document.getElementById("game-debug-overlay");
        this.framerateMeter = new FramerateMeter(1, (fps) => {
            if (elementById) {
                elementById.innerText = "FPS: "+fps.toFixed(1);
            }
        });
    }


    async init() {
        let colors = [0xff0000, 0x00ff00, 0x0000f0, 0xffff00, 0x00ffff, 0xff00ff];

        this.modelLoader.defineTextureMaterial("fir-branch", "fir-branch.png", 1);
        this.modelLoader.defineTextureMaterial("Material.004", "grass.png", 32);
        this.modelLoader.defineMaterial("Body.007", new THREE.MeshPhongMaterial({color:"#c00020",
        specular:"#ffffff", shininess:50}));
        let selectedCube : Mesh|null = null;
        let oldMaterial : Material|null = null;
        for (let i = 0; i < 50; ++i) {
            let cube = new GameObject();
            cube.renderObject = this.renderSystem.createCube(20, 0, i * 1.2, 1, 1, 1, colors[i % colors.length]);
            cube.renderObject.onClick = () => {
                let debugConsole = document.getElementById("console");
                if (debugConsole) {
                    debugConsole.innerText += `Clicked ID: ${cube.renderObject?.mesh.uuid}\n`;
                }
                if (selectedCube != null && oldMaterial!=null) {
                    selectedCube.material = oldMaterial;
                }
                let mesh = cube.renderObject?.mesh;
                if (mesh && (mesh instanceof Mesh)) {
                    selectedCube = mesh as Mesh;
                    oldMaterial = mesh.material as Material;
                    mesh.material = new THREE.MeshPhongMaterial({color:"#ff00ff"});
                    let properties = document.getElementById("properties-editor");
                    if (!properties) {
                        return;
                    }
                    properties.innerHTML=`
<h3>Properties</h3>
<dl>
    <dh>x:</dh><dd>${selectedCube?.position.x.toFixed(3)}</dd>
    <dh>y:</dh><dd>${selectedCube?.position.y.toFixed(3)}</dd>
    <dh>z:</dh><dd>${selectedCube?.position.z.toFixed(3)}</dd>
</dl>
`;
                }
            }
            cube.physicsObject = this.physicsSystem.createCube(20+Math.cos(i / 2) * 3, Math.sin(i / 2) * 3, 10+i * .2, 1, 1, 1, 50)
                .setRotation(0.7, 0, 0);
            this.gameSystem.addObject(cube);
        }
            this.material2 = new THREE.ShaderMaterial({
                side: THREE.DoubleSide,
                uniforms: {
                    time: { value: 0.5 },
                },
                vertexShader: await (await fetch('sky.vert')).text(),
                fragmentShader: await (await fetch('sky.frag')).text()});

            let sky = new GameObject();
            const geometry = new THREE.SphereGeometry(20000, 25,25);
            const plane = new THREE.Mesh(geometry, this.material2);
            plane.position.set(0,0,-20000+500);
            sky.renderObject = new RenderObject(plane);
            this.renderSystem.scene.add(plane);
            this.gameSystem.addObject(sky);
        {
            let groundPlane = new GameObject();
            const geometry = new THREE.PlaneGeometry(100000, 100000);
            const material = new THREE.MeshPhongMaterial({color: 0x425b39, side: THREE.DoubleSide});
            const plane = new THREE.Mesh(geometry, material);
            plane.position.set(0,0,-2);
            groundPlane.renderObject = new RenderObject(plane);
            this.renderSystem.scene.add(plane);
            this.gameSystem.addObject(groundPlane);
        }
/*
        let leftGround = new GameObject();
        leftGround.renderObject = this.renderSystem.createCube(10,-10,1, 10, 60, 1, colors[0]);
        leftGround.physicsObject = this.physicsSystem.createCube(10,-10,1,9.5, 60, 1, 0).setRotation(0.0, 0, 0);
        this.gameSystem.addObject(leftGround);
        let rightGround = new GameObject();
        rightGround.renderObject = this.renderSystem.createCube(10,-10,1, 10, 60, 1, colors[1]);
        rightGround.physicsObject = this.physicsSystem.createCube(-0.5,-10,1,9.5, 60, 1, 0).setRotation(0.0, 0, 0);
        this.gameSystem.addObject(rightGround);
*/
     //   await this.gameSystem.addFBX("balll.fbx",10,-10,20, 1, true, false);

        // Forest
        for (let x=-90; x<90; x+=20) {
            for (let y=-90; y<90; y+=20) {
                if (Math.abs(x)>20 || Math.abs(y)>20) {
                    let gameObject = await this.gameSystem.addObj("trianglefir3.obj", x + Math.random() * 20, y + Math.random() * 20, 14, 0, true, false, false);
                    let width = 0.6 + Math.random() * 0.4;
                    gameObject.renderObject?.mesh.scale.multiply(new Vector3(width, width, 0.6 + Math.random() * 0.4));
                    gameObject.physicsObject?.setRotation(0, 0, Math.random() * 360);
                }
            }
        }

       this.car = await this.gameSystem.addGLTF("sportscar.glb",5,3,3, 1000, true, true,true);
        if (this.car.physicsObject?.body) {
            this.physicsSystem.world.removeBody(this.car.physicsObject?.body);
        }
        this.car.renderOffset = new Vector3(0,0,0);
        this.car.physicsObject = this.physicsSystem.createCube(6.23, 3.03, 3, 4.40, 1.99, 0.17, 150);
        let chassisBody: CANNON.Body = this.car.physicsObject?.body;
        chassisBody.linearDamping = 0.9;
        this.vehicle.chassis = chassisBody;
        let wheels = [
            {
                position : new Vec3(-1.7, 1, -0.1)
            },
            {
                position : new Vec3(-1.7, -1, -0.1)
            },
            {
                position : new Vec3(1.7, 1, -0.1)
            },
            {
                position : new Vec3(1.7, -1, -0.1)
            },
        ]
        const wheelRadius = 0.5;
        const wheelBodies: CANNON.Body[] = []
        const wheelMaterial = this.physicsSystem.getOrCreateMaterial("default");
        //  this.physicsSystem.defineFriction("default", "wheel",1);
     //   let q = new CANNON.Quaternion();
     //   q.setFromAxisAngle(new CANNON.Vec3(1,0,0), 1.5);
        wheels.forEach((wheel) => {
            const cylinderShape = new CANNON.Sphere(wheelRadius);
            let wheelPosition = chassisBody?.pointToWorldFrame(wheel.position);
            const wheelBody = new CANNON.Body({
                mass: 10,
                material: wheelMaterial,
                shape: cylinderShape,
                position: wheelPosition,
            });
         //   wheelBody.type = CANNON.Body.KINEMATIC
        //    wheelBody.collisionFilterGroup = 0 // turn off collisions
            const quaternion = new CANNON.Quaternion().setFromEuler(0, 0, 0);
            //wheelBody.addShape(cylinderShape, wheel.position, quaternion)
            wheelBodies.push(wheelBody)
          //  chassisBody?.addShape(cylinderShape, wheel.position, quaternion);
            wheelBody.angularVelocity = new Vec3(0,0,0);
            wheelBody.angularDamping = 0.0;
            this.physicsSystem.world.addBody(wheelBody)
            let axis = new Vec3(0,1,0);
            const hingeConstraint = new CANNON.HingeConstraint(chassisBody, wheelBody, {
                pivotA: wheel.position,
                axisA: axis,
                pivotB: Vec3.ZERO,
                axisB: axis,
                collideConnected: false,
            })
            this.vehicle.wheels.push(hingeConstraint);
            this.physicsSystem.world.addConstraint(hingeConstraint);
        })

        await this.gameSystem.addFBX("ground.fbx",0,0,-1, 0, false, true);

        this.forwardForce = 0;
        this.backwardForce = 0;
        this.turnRightTorque = 0;
        this.turnLeftTorque = 0;
        document.addEventListener("keydown",  (event) => {
            if (event.defaultPrevented) {
                return;
            }
            switch (event.key) {
                case "ArrowDown":
                 thisGame.backwardForce = 10000;
                    break;
                case "ArrowUp":
                    thisGame.forwardForce = 10000;
                    break;
                case "ArrowLeft":
                    thisGame.turnLeftTorque = 1;
                    break;
                case "ArrowRight":
                    thisGame.turnRightTorque = 1;
                    break;
                default:
                    return;
            }
            event.preventDefault();
        }, true);
        document.addEventListener("keyup", (event)=> {
            if (event.defaultPrevented) {
                return;
            }
            switch (event.key) {
                case "ArrowDown":
                    thisGame.backwardForce = 0;
                    break;
                case "ArrowUp":
                    thisGame.forwardForce = 0;
                    break;
                case "ArrowLeft":
                    thisGame.turnLeftTorque = 0;
                    break;
                case "ArrowRight":
                    thisGame.turnRightTorque = 0;
                    break;
                default:
                    return;
            }
            event.preventDefault();
        }, true);

        this.hasOldCamera = false;
        this.oldCameraVector = new THREE.Vector3(0, 0,0);
        this.lastTime = 0;

        this.gameSystem.update(0);
    }

    animate(currentTime: DOMHighResTimeStamp) {
        thisGame.physicsDebugRenderer.update();
        if (thisGame.lastTime == 0) {
            thisGame.lastTime = currentTime;
        }
        thisGame.material2.uniforms.time.value = currentTime/1000;
        let dt = currentTime - thisGame.lastTime;
        if (dt>100) {
            dt = 100;
        }
        thisGame.framerateMeter.update(dt/1000.0);

        let rotation = new CANNON.Vec3(0,0,0);
        //let quaternion = thisGame.car?.physicsObject?.body.quaternion;
      //  quaternion?.toEuler(rotation);
/*
        if (quaternion && (thisGame.forwardForce || thisGame.backwardForce || thisGame.turnLeftTorque || thisGame.turnRightTorque))
        {
            thisGame.car?.physicsObject?.body.wakeUp();
            let force = new CANNON.Vec3(0, thisGame.backwardForce-thisGame.forwardForce, 0);
            let rotatedForce = new CANNON.Vec3();
            rotatedForce = quaternion.vmult(force,rotatedForce);
            console.log(rotatedForce);
            //force = force.rotate(transform.getRotation().getAxis(), transform.getRotation().getAngle());
            let point = new CANNON.Vec3(0, 0, 0);
            thisGame.car?.physicsObject?.body.applyForce(rotatedForce, point);
            let angularVelocity = thisGame.car?.physicsObject?.body.angularVelocity.length();
            if (angularVelocity && angularVelocity<1) {
                let torque = new CANNON.Vec3(0,0,thisGame.turnLeftTorque-thisGame.turnRightTorque);
                thisGame.car?.physicsObject?.body.applyTorque(torque);
            }
        }

 */

//        this.vehicle.wheels[0].enableMotor();
 //       this.vehicle.wheels[0].setMotorMaxForce(thisGame.forwardForce-thisGame.backwardForce);
  //      this.vehicle.wheels[0].setMotorSpeed(100);
/*
        thisGame.vehicle?.applyEngineForce(thisGame.forwardForce-thisGame.backwardForce,0);
        thisGame.vehicle?.applyEngineForce(thisGame.forwardForce-thisGame.backwardForce,1);
*/
        let chassis = thisGame.vehicle.chassis;
       /* if (thisGame.forwardForce==0 && thisGame.backwardForce==0) {
            if (chassis && chassis.velocity.vmul(new Vec3(1,1,0)).length() > 1) {
                let brakeForce = chassis.velocity.clone();
                brakeForce = brakeForce.vmul(new Vec3(-200,-200,0));
                chassis?.applyLocalForce( brakeForce, chassis.);
            }
        }*/


        chassis?.applyLocalForce( new Vec3(thisGame.backwardForce-thisGame.forwardForce,1,0), new Vec3(1.5, 0.0,0),)

//        if (quaternion && (thisGame.forwardForce || thisGame.backwardForce || thisGame.turnLeftTorque || thisGame.turnRightTorque)){
 //           thisGame.vehicle?.applyEngineForce(thisGame.forwardForce-thisGame.backwardForce,0);
         //   thisGame.vehicle?.applyEngineForce(thisGame.forwardForce+thisGame.backwardForce,1);
   //     }

            thisGame.gameSystem.update(dt);
        thisGame.lastTime = currentTime;
        window.requestAnimationFrame(thisGame.animate);
    }
}

thisGame = new Game();
thisGame.init().then(() => {
    thisGame.animate(performance.now());
});
