export class EnumSetting<T> {


    private listeners: ((value: T) => void)[] = [];
    private _value: T;
    private _values: readonly [T, ...T[]];

    constructor(enumObject: readonly [T, ...T[]], value : T=enumObject[0]) {
        this._values = enumObject;
        this._value=value;
    }

    listen(listener: (value: T) => void, callImmediately : boolean = false) {
        this.listeners.push(listener);
        if (callImmediately) {
            listener(this.value);
        }
        return this;
    }

    set value(value: T) {
        this._value = value;
        this.listeners.forEach(listener => {
           listener(value);
        });
    }

    get value(): T {
        return this._value;
    }
    get values(): readonly [T, ...T[]] {
        return this._values;
    }
}
