import {SettingsEditor} from "./settingssystem/SettingsEditor";
import {BooleanSetting} from "./settingssystem/BooleanSetting";
import {EnumSetting} from "./settingssystem/EnumSetting";
import {NumberSetting} from "./settingssystem/NumberSetting";
import {VectorSetting} from "./settingssystem/VectorSetting";

const ORenderMode = {
    Normal:0,
    NoPostEffects:1,
    Wireframe:2,
    Nothing:3
} as const;

const ARenderMode = ["Normal", "NoPostEffects", "Wireframe","Nothing"] as const;
export type RenderMode = typeof ARenderMode[number];

export class DebugSettings {
    showPhysics = new BooleanSetting(false);
    renderMode = new EnumSetting<RenderMode>(ARenderMode, "Normal");
    cameraYaw = new NumberSetting(1.422);
    cameraPitch = new NumberSetting(-0.508);
    cameraPosition = new VectorSetting(55.54, -2.31, 34.00);

    constructor() {
    }

    getProperties(editorContext : SettingsEditor) {
        editorContext.addBoolean("showPhysics", this.showPhysics);
        editorContext.addEnum("renderMode", this.renderMode);
        editorContext.addNumber("cameraYaw", this.cameraYaw);
        editorContext.addNumber("cameraPitch", this.cameraPitch);
        editorContext.addVector("cameraPosition", this.cameraPosition);
    }
}