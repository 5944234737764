import * as CANNON from 'cannon-es'
import {PhysicsObject} from "./PhysicsObject";
import {milliseconds} from "./types";

export class PhysicsSystem {

    static STATE = {
        ACTIVE : 1,
        ISLAND_SLEEPING : 2,
        WANTS_DEACTIVATION : 3,
        DISABLE_DEACTIVATION : 4,
        DISABLE_SIMULATION : 5
    }


    public readonly world = new CANNON.World({
        gravity: new CANNON.Vec3(0, 0, -9.82), // m/s²
    })
    public readonly bodies: CANNON.Body[] = []
    public materials = new Map<string, CANNON.Material>();
    public contactMaterials = new Map<string, CANNON.ContactMaterial>();
    private timeLeft: milliseconds = 0.0;

    constructor() {
        this.defineFriction("default","default", 0.3);
    }

    defineFriction(material1Name : string, material2Name : string, friction : number) {
        const key = material2Name+"<->"+material2Name;
        let existinContactMaterial = this.contactMaterials.get(key)
        if (existinContactMaterial)
        {
            this.world.removeContactMaterial(existinContactMaterial)
        }
        const material1 = this.getOrCreateMaterial(material1Name);
        const material2 = this.getOrCreateMaterial(material2Name);
        const contactMaterial = new CANNON.ContactMaterial(material1, material2, {
            friction: friction,
        });
        this.contactMaterials.set(key, contactMaterial);
        this.world.addContactMaterial(contactMaterial);
    }
    createCube(x:number,y:number,z:number, width:number, height:number, depth:number, mass:number) : PhysicsObject
    {
        const cube = new CANNON.Body({
            mass: mass as number, // kg
            shape: new CANNON.Box(new CANNON.Vec3(width/2, height/2, depth/2)),
            position: new CANNON.Vec3(x,y,z),
            material: this.getOrCreateMaterial("default")
        });
        this.world.addBody(cube);
        this.bodies.push(cube);

        return new PhysicsObject(cube);
    }

    public simulate(dt: milliseconds) {
        this.timeLeft += dt;
        const stepSize : milliseconds = 1000/120;
        while (this.timeLeft >= stepSize) {
            this.world.step(stepSize/1000*1.0);
            this.timeLeft-= stepSize;
        }
        //this.world.step(1/145, dt/1000);
    }

    public getOrCreateMaterial(materialName: string) : CANNON.Material{
        let existingMaterial = this.materials.get(materialName)
        if (existingMaterial) {
            return existingMaterial;
        }
        let material = new CANNON.Material("default");
        this.materials.set(materialName, material);
        return material;
    }
}