export class FramerateMeter {
    private callback : (fps : number) => void;
    private callbackRateSeconds: number;
    private timePassed: number = 0;
    private frameCount: number = 0;
    constructor(callbackRateSeconds: number, callback: (fps: number) => void) {
        this.callback = callback;
        this.callbackRateSeconds = callbackRateSeconds;
    }

    update(dtSeconds : number) {
        this.timePassed += dtSeconds;
        this.frameCount++;
        if (this.timePassed > this.callbackRateSeconds) {
            this.callback(this.frameCount/this.timePassed);
            this.frameCount = 0;
            this.timePassed = 0;
        }
    }
}