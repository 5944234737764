import {inflate} from "zlib";

export class HTML {

    static tag<K extends keyof HTMLElementTagNameMap>(tagName:K, text : string, ...children : HTMLElement[]): HTMLElementTagNameMap[K] {
        let element = document.createElement(tagName);
        element.innerText = text;
        children.forEach(child => {
            element.appendChild(child);
        });
        return element;
    }

    static div(attributes : {}, text : string, ...children : HTMLElement[]) : HTMLDivElement {
        return this.tag("div", text, ...children);
    }


    static label(attributes : {}, text : string, ...children : HTMLElement[]) : HTMLLabelElement
    {
        return this.tag("label", text, ...children);
    }


    static input(attributes : {type?:"text"}, text : string, ...children : HTMLElement[]) : HTMLInputElement
    {
        let element = this.tag("input", text, ...children);
        element.setAttribute("type","kjh");
        attributes.type?element.type=attributes.type:0;
        return element;
    }

    static appendChild<T extends HTMLElement, S extends HTMLElement>(parent: T, child : S) : S {
        parent.appendChild(child);
        return child;
    }
}