import {Vector3} from "three";

export class DebugLogger{
    private debugConsole: HTMLElement | null = null;

    constructor() {

    }

    getConsole(): HTMLElement | null {
        if (!this.debugConsole) {
            this.debugConsole = document.getElementById("console");
        }

        return this.debugConsole;
    }

    addString(text: string) {
        let debugConsole = this.getConsole();
        if (debugConsole) {
            debugConsole.innerText += `${text}`;
            debugConsole.scrollTo(0, debugConsole.scrollHeight);
        }

    }

    print(...args : (string|Vector3|number)[]) {
        args.forEach(value => {
            if (value instanceof Vector3) {
                this.addString(this.v2s(value));
            } else if (typeof (value) === "number")
            {
                let number = value as number;
                this.addString(number.toFixed(2));
            }else {
                this.addString(value);
            }
        });
        this.addString("\n");
    }

    v2s(vector: Vector3) {
        return `[${vector.x.toFixed(2)}, ${vector.y.toFixed(2)}, ${vector.z.toFixed(2)}]`;
    }

}