import {
    Material,
    Mesh,
    OrthographicCamera,
    PlaneGeometry,
    Scene,
    ShaderMaterial,
    WebGLRenderer,
    WebGLRenderTarget
} from "three";
import {IUniform} from "three/src/renderers/shaders/UniformsLib";

export class MyBlur {
    private readonly renderer: WebGLRenderer;
    private readonly scene: Scene;
    private readonly camera: OrthographicCamera;
    private readonly inputDepthTexture: WebGLRenderTarget;
    private readonly inputColorTexture: WebGLRenderTarget;
    private distanceBlurMaterial: ShaderMaterial = new ShaderMaterial();
    private quad: Mesh<PlaneGeometry, ShaderMaterial> = new Mesh(new PlaneGeometry(1,1), this.distanceBlurMaterial);
    private depthCaptureMaterial :ShaderMaterial = new ShaderMaterial();
    private isReady: boolean = false;
    private readonly uniforms: { [uniform: string]: IUniform } ;

    async loadShaders() {
     this.depthCaptureMaterial =  new ShaderMaterial( {
            uniforms: {},
            vertexShader: await (await fetch('depthCapture.vert')).text(),
            fragmentShader: await (await fetch('depthCapture.frag')).text()
        } );

        this.distanceBlurMaterial = new ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: await (await fetch('distanceBlur.vert')).text(),
            fragmentShader: await (await fetch('distanceBlur.frag')).text(),
        })
    }

    constructor(renderer: WebGLRenderer, width: number, height: number, near : number, far:number) {
        this.renderer = renderer;
        this.scene = new Scene();

        this.camera = new OrthographicCamera(width / -2, width/ 2, height / 2, height / -2, -10000, 1000000);
        this.camera.position.z = 100;

        this.scene.add(this.camera);

        this.inputDepthTexture = new WebGLRenderTarget(width, height);
        this.inputColorTexture = new WebGLRenderTarget(width, height);
        this.uniforms = {};
        this.uniforms["tColor"] = {value:this.inputColorTexture.texture}
        this.uniforms["tDepth"] = {value:this.inputDepthTexture.texture}
        this.uniforms["width"] ={value:width}
        this.uniforms["height"] ={value:height}
        this.uniforms["near"] ={value:near}
        this.uniforms["far"] ={value:far}

        this.loadShaders().then(_ => {
            this.quad = new Mesh(new PlaneGeometry(1,1), this.distanceBlurMaterial);
            this.quad.geometry.scale(width, height,1);
            this.quad.position.z = -500;
            this.scene.add(this.quad);
            this.isReady = true;
        });
    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    setSize(width: number, height: number) {
        this.inputDepthTexture.setSize(width, height);
        this.inputColorTexture.setSize(width, height);
        this.camera.left = width / -2;
        this.camera.right = width/ 2;
        this.camera.top = height / 2;
        this.camera.bottom = height / -2;
        this.camera.updateProjectionMatrix();
        this.uniforms["width"].value=width;
        this.uniforms["height"].value=height;
        // Potential race condition, if the shaders aren't loaded yet, it's not added to the scene.
        this.scene.remove(this.quad);
        this.quad = new Mesh(new PlaneGeometry(1,1), this.distanceBlurMaterial);
        this.quad.geometry.scale(width, height,1);
        this.quad.position.z = -500;
        this.scene.add(this.quad);
    }

    setNearFar(near : number, far:number) {
        this.uniforms["near"] ={value:near}
        this.uniforms["far"] ={value:far}

        this.depthCaptureMaterial.uniforms[ 'mNear' ].value = near;
        this.depthCaptureMaterial.uniforms[ 'mFar' ].value = far;
    }

    renderInput(callback : (overrideMaterial : Material | null) => void) {
        if (!this.isReady) {
            callback(null);
            return;
        }
        let renderer = this.renderer;
        renderer.setRenderTarget(this.inputColorTexture);
        callback(null);

        renderer.shadowMap.enabled = false;
        this.scene.overrideMaterial = this.depthCaptureMaterial;
        renderer.setRenderTarget(this.inputDepthTexture);
        callback(this.depthCaptureMaterial);
        renderer.shadowMap.enabled = true;
        this.scene.overrideMaterial = null;

    }
}