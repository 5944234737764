export class NumberSetting {
    private listeners: ((value: number) => void)[] = [];

    constructor(value:number = 0.0) {
        this._value = value;
    }

    listen(listener: (value: number) => void, callImmediately : boolean = false) {
        this.listeners.push(listener);
        if (callImmediately) {
            listener(this.value);
        }
        return this;
    }

    set value(value: number) {
        this._value = value;
        this.listeners.forEach(listener => {
            listener(value);
        });
    }

    get value(): number {
        return this._value;
    }

    private _value: number = 0.0;
}
