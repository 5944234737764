import {BoxGeometry, CylinderGeometry, Mesh, MeshBasicMaterial, Scene, SphereGeometry} from "three";
import {World, Vec3, Sphere, Box, Cylinder, Quaternion as Quat} from "cannon-es";
import {DebugLogger} from "./DebugLogger";
import {DebugSettings} from "./DebugSettings";

export class CannonThreeJSDebugger {
    private debugLogger: DebugLogger;
    private debugSettings: DebugSettings;
    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }
    private scene: Scene;
    private world: World;
    private shapeIdToMesh = new Map<number, Mesh>();

    private _enabled : boolean = false;

    constructor(debugLogger: DebugLogger, scene: Scene, world: World, debugSettings: DebugSettings) {
        this.debugLogger = debugLogger;
        this.scene = scene;
        this.world = world;
        this.debugSettings = debugSettings;
        this.debugSettings.showPhysics.listen(value => {
            this.enabled = value==true;
        },true);
    }

    update()
    {
        const shapeWorldPosition = new Vec3();
        const shapeWorldQuaternion =  new Quat();
        let activeShapeIds = new Set<number>();
        if (this.enabled) {
            this.world.bodies.forEach(body => {
                for (let i = 0; i < body.shapes.length; i++) {
                    const shape = body.shapes[i];
                    if (!(body.collisionResponse && shape.collisionResponse)) {
                        continue;
                    }
                    if (shape instanceof Box ) {
                        activeShapeIds.add(shape.id);
                        let mesh = this.shapeIdToMesh.get(shape.id);
                        if (!mesh) {
                            mesh = new Mesh(new BoxGeometry(1, 1, 1), new MeshBasicMaterial({
                                color: 0xff00fff,
                                wireframe: true
                            }))
                            this.shapeIdToMesh.set(shape.id, mesh);
                            this.scene.add(mesh);
                        }
                        mesh.scale.set(shape.halfExtents.x, shape.halfExtents.y, shape.halfExtents.z);
                        mesh.scale.multiplyScalar(2);
                        body.quaternion.vmult(body.shapeOffsets[i], shapeWorldPosition)
                        body.position.vadd(shapeWorldPosition, shapeWorldPosition)
                        body.quaternion.mult(body.shapeOrientations[i], shapeWorldQuaternion)
                        mesh.position.set(shapeWorldPosition.x, shapeWorldPosition.y, shapeWorldPosition.z);
                        mesh.quaternion.set(shapeWorldQuaternion.x, shapeWorldQuaternion.y, shapeWorldQuaternion.z, shapeWorldQuaternion.w);
                    } else if (shape instanceof Cylinder ) {
                        activeShapeIds.add(shape.id);
                        let mesh = this.shapeIdToMesh.get(shape.id);
                        if (!mesh) {
                            mesh = new Mesh(new CylinderGeometry(shape.radiusTop, shape.radiusBottom, shape.height, shape.numSegments), new MeshBasicMaterial({
                                color: 0xff00fff,
                                wireframe: true
                            }))
                            this.shapeIdToMesh.set(shape.id, mesh);
                            this.scene.add(mesh);
                        }
                        mesh.scale.set(0.5,0.5,0.5);
                        body.quaternion.vmult(body.shapeOffsets[i], shapeWorldPosition)
                        body.position.vadd(shapeWorldPosition, shapeWorldPosition)
                        body.quaternion.mult(body.shapeOrientations[i], shapeWorldQuaternion)
                        mesh.position.set(shapeWorldPosition.x, shapeWorldPosition.y, shapeWorldPosition.z);
                        mesh.quaternion.set(shapeWorldQuaternion.x, shapeWorldQuaternion.y, shapeWorldQuaternion.z, shapeWorldQuaternion.w);
                    } else if (shape instanceof Sphere ) {
                        activeShapeIds.add(shape.id);
                        let mesh = this.shapeIdToMesh.get(shape.id);
                        if (!mesh) {
                            mesh = new Mesh(new SphereGeometry(shape.radius), new MeshBasicMaterial({
                                color: 0xff00fff,
                                wireframe: true
                            }))
                            this.shapeIdToMesh.set(shape.id, mesh);
                            this.scene.add(mesh);
                        }
                        body.quaternion.vmult(body.shapeOffsets[i], shapeWorldPosition)
                        body.position.vadd(shapeWorldPosition, shapeWorldPosition)
                        body.quaternion.mult(body.shapeOrientations[i], shapeWorldQuaternion)
                        mesh.position.set(shapeWorldPosition.x, shapeWorldPosition.y, shapeWorldPosition.z);
                        mesh.quaternion.set(shapeWorldQuaternion.x, shapeWorldQuaternion.y, shapeWorldQuaternion.z, shapeWorldQuaternion.w);
                    }  else {
                        this.debugLogger.print("Unknown shape: ", shape.constructor.name);
                    }
                }
            })
        }
        this.shapeIdToMesh.forEach((mesh, shapeId) => {
            if (!activeShapeIds.has(shapeId)) {
                this.scene.remove(mesh);
                this.shapeIdToMesh.delete(shapeId);
            }
        });
    }
}