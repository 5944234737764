import {BooleanSetting} from "./BooleanSetting";
import {div} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";
import {EnumSetting} from "./EnumSetting";
import {NumberSetting} from "./NumberSetting";
import {VectorSetting} from "./VectorSetting";
import {HTML} from "../HTML";

export class SettingsEditor {
    private div: HTMLElement;
    constructor(div: HTMLElement) {
        this.div = div;
    }

    addBoolean(label: string, setting: BooleanSetting) {
        let labelElement = document.createElement("label");
        labelElement.innerText = label;
        let checkBox = document.createElement("input");
        checkBox.type = "checkbox";
        labelElement.appendChild(checkBox);
        this.div.appendChild(labelElement);
        setting.listen(value => {
            checkBox.checked = value;
        }, true);
        checkBox.addEventListener("change", ev => {
            setting.value = checkBox.checked;
        });
    }

    addNumber(label: string, setting: NumberSetting) {
        let labelElement = document.createElement("label");
        labelElement.innerText = label;
        let textInputElement = document.createElement("input");
        textInputElement.type = "text";
        labelElement.appendChild(textInputElement);
        this.div.appendChild(labelElement);
        textInputElement.addEventListener("change", ev => {
            setting.value = Number.parseFloat(textInputElement.value);
        });
        setting.listen(value => {
             textInputElement.value  = value.toFixed(3);
        }, true);
    }

    addEnum<T extends string>(label: string, setting: EnumSetting<T>) {
        let labelElement = document.createElement("label");
        labelElement.innerText = label;
        let select = document.createElement("select");
        labelElement.appendChild(select);
        this.div.appendChild(labelElement);
        setting.values.forEach(enumValue => {
            let option = document.createElement("option");
            option.value=enumValue;
            option.innerText=enumValue;
            select.appendChild(option);
        })
        select.addEventListener("change", ev => {
            setting.value = select.value as T;
        });
        setting.listen(value => {
            select.value = value;
        }, true)
    }

    addVector(label: string, setting: VectorSetting) {
        let labelElement = HTML.appendChild(this.div, HTML.label({}, label))
        let textInputElement = HTML.appendChild(labelElement, HTML.input({type:"text"}, ""));
        textInputElement.addEventListener("change", ev => {
            //setting.value = Number.parseFloat(textInputElement.value);
        });
        setting.listen(value => {
            textInputElement.value  =
                value.x.toFixed(2)+", "+
                value.y.toFixed(2)+", "+
                value.z.toFixed(2);
            ;
        }, true);
    }
}