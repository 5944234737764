
export class DragListener {
    private mouseIsDown: boolean = false;
    private lastX: number = 0;
    private lastY: number = 0;
    private callbacks : ((dx : number, dy:number) => void)[] = [];
    constructor(element : HTMLElement) {
        element.addEventListener("mousemove", (e)=> {
           return this.onMouseMove(e);
        });
        element.addEventListener("mousedown", (e)=> {
            return this.onMouseDown(e);
        });
        element.addEventListener("mouseup", (e)=> {
            return  this.onMouseUp(e);
        });
        element.addEventListener("mouseleave", (e)=> {
            return  this.onMouseUp(e);
        });
        element.addEventListener("mouseenter", (e)=> {
            return  this.onMouseDown(e);
        });
    }

    private onMouseMove(e: MouseEvent) {
        if (this.mouseIsDown) {
            let dx = e.x - this.lastX;
            let dy = e.y - this.lastY;
            this.callbacks.forEach(callback => {
                callback(dx, dy);
            });
        }
        this.lastX = e.x;
        this.lastY = e.y;
        return false;
    }

    addDeltaListener(deltaListener : (dx : number, dy: number) => void) {
        this.callbacks.push(deltaListener);
    }

    removeDeltaListnere(deltaListener : (dx : number, dy: number) => void) {
        this.callbacks= this.callbacks.filter(value => {value !== deltaListener});
    }

    private onMouseDown(e: MouseEvent) {
        if (e.buttons & 1) {
            this.lastX = e.x;
            this.lastY = e.y;
            this.mouseIsDown = true;
        }
        e.preventDefault();
        return false;
    }

    private onMouseUp(e: MouseEvent) {
        this.mouseIsDown = false;
        return false;
    }
}